import React, { Component } from 'react'
import Slider from 'react-slick'
import { Segment, Header, Grid, Container, Divider } from 'semantic-ui-react/dist/commonjs'
import { Trans } from 'react-i18next'

import { unescape } from '../../utils/helpers'

export default class TestimonialsSlider extends Component {
  next = () => {
    this.slider.slickNext()
  }

  prev = () => {
    this.slider.slickPrev()
  }

  render () {
    const settings = {
      className: 'center',
      infinite: true,
      arrows: false,
      lazyLoad: true,
      centerPadding: '20px',
      slidesToShow: 1,
      swipeToSlide: true,
      dots: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            arrows: false
          }
        }
      ]
    }
    const { testimonials = [] } = this.props
    return (
      <Grid.Column>
        <Segment basic vertical padded='very'>
          <Container>
            <Grid padded>
              <Grid.Row centered>
                <Grid.Column computer={2} verticalAlign='middle' only='computer'>
                  <i className='icon ta-double-arrow-left huge red' onClick={this.prev} style={{ cursor: 'pointer' }} />
                </Grid.Column>
                <Grid.Column computer={12} tablet={16} mobile={16}>
                  <Header as='h2' textAlign='center'>
                    <Trans i18nKey='nav-credentials.header'>Das sagen <span className='color-red'>unsere Kunden</span></Trans>
                  </Header>
                  <Slider ref={c => (this.slider = c)} {...settings}>
                    {testimonials.map(({ node: { id, content, title: author } }) => (
                      <Segment basic padded key={id} textAlign='center'>
                        <h3 dangerouslySetInnerHTML={{ __html: content }} />
                        <Header as='h5' color='red'>{unescape(author)}</Header>
                      </Segment>
                    ))}
                  </Slider>
                </Grid.Column>
                <Grid.Column computer={2} verticalAlign='middle' only='computer'>
                  <i className='icon ta-double-arrow-right huge red' onClick={this.next} style={{ cursor: 'pointer', float: 'right' }} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider clearing hidden fitted />
          </Container>
        </Segment>
      </Grid.Column>
    )
  }
}
