import React from 'react'
import { Container, Header, Segment, Card } from 'semantic-ui-react'
import { withTranslation, Trans } from 'react-i18next'

import BlogCard from './BlogCard'

const BlogPosts = ({ blogs }) => {
  return (
    <Segment vertical basic padded='very' className='gradient' inverted>
      <Container>
        <Header as='h2'>
          <Trans i18nKey='homepage.carousel.blogs'>Reiseberichte unserer <span className='tour-red'>Asien-Spezialisten</span></Trans>
        </Header>
        <Card.Group itemsPerRow={3} stackable>
          {blogs.map(({ node }) => {
            return <BlogCard key={node.id} blog={node} />
          })}
        </Card.Group>
      </Container>
    </Segment>
  )
}

export default withTranslation()(BlogPosts)
