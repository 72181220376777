import React from 'react'
import {
  graphql
} from 'gatsby'
import {
  withTranslation
} from 'react-i18next'

import Layout from '../components/Layouts/HomepageLayout'
import {
  getFinderOptions
} from '../utils/options'

const IndexPage = ({
  data
}) => {
  const countries = data.allFirestoreCountry.edges
  const locations = data.allFirestoreLocation.edges
  const regions = data.allFirestoreRegion.edges
  const items = data.allFirestoreHotel.edges
  const specials = data.allFirestoreSpecial ? data.allFirestoreSpecial.edges : []
  const categories = data.allFirestoreCategory.edges
  const blogs = data.allWordpressPost.edges
  const testimonials = data.allWordpressWpCredential ? data.allWordpressWpCredential.edges : []
  const texts = data.allFirestoreText.edges
  const trips = data.allFirestoreTrip ? data.allFirestoreTrip.edges : []
  const tours = data.allFirestoreTour ? data.allFirestoreTour.edges : []

  const finderOptions = getFinderOptions(countries, regions, locations)
  const homeImages = data.allFirestoreImage ? data.allFirestoreImage.edges : null
  const introImage = homeImages ? homeImages[Math.floor(Math.random() * homeImages.length)] : ''

  return ( <
    Layout countries = {
      countries
    }
    items = {
      items
    }
    specials = {
      specials
    }
    categories = {
      categories
    }
    testimonials = {
      testimonials
    }
    blogs = {
      blogs
    }
    image = {
      introImage
    }
    finderOptions = {
      finderOptions
    }
    texts = {
      texts
    }
    hotels = {
      items
    }
    tours = {
      tours
    }
    trips = {
      trips
    }
    />
  )
}

export default withTranslation()(IndexPage)

export const pageQuery = graphql `
  query IndexPage($lang: String!) {
    allWordpressPost(filter: { acf: { language: { eq: $lang } } }, limit: 3, sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          wordpress_id
          date
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          acf {
            blog_author
            language
          }
          yoast {
            metadesc
          }
          author {
            name
          }
          title
          excerpt
        }
      }
    }
    allFirestoreImage(filter: { page: { eq: "home" } }) {
      edges {
        node {
          cloudinary
          page
          transformation
          title
          alt
        }
      }
    }
    allFirestoreCategory(filter: { lang: { eq: $lang } }, sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
          service
          type
          lang
        }
      }
    }
    allFirestoreCountry(filter: { lang: { eq: $lang } }, sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
          slug
          collection
          lang
          slug
        }
      }
    }
    allFirestoreLocation(filter: { lang: { eq: $lang } }, sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
          country
          region
          lang
          countrySlug
          regionSlug
          slug
        }
      }
    }
    allFirestoreRegion(filter: { lang: { eq: $lang } }, sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
          country
          lang
          countrySlug
          slug
        }
      }
    }
    allFirestoreHotel(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          id
          title
          country
          region
          location
          intro
          collection
          coverImage
          slug
          countrySlug
          lang
          mergedCode
        }
      }
    }
    allFirestoreText(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          id
          section
          header
          content
          image {
            cloudinary
            title
            transformation
          }
          lang
        }
      }
    }
    allWordpressWpCredential(filter: { acf: { language: { eq: $lang } } }) {
      edges {
        node {
          id
          title
          content
          acf {
            language
          }
        }
      }
    }
    allFirestoreTour (filter: { lang: { eq: $lang } }) {
      edges {
        node {
          mergedCode
          slug
          collection
          countrySlug
          lang
        }
      }
    }
    allFirestoreTrip (filter: { lang: { eq: $lang } }) {
      edges {
        node {
          mergedCode
          slug
          collection
          countrySlug
          lang
        }
      }
    }
    # allFirestoreSpecial(filter: { lang: { eq: $lang } }, limit: 3) {
    #   edges {
    #     node {
    #       id
    #       title
    #       body
    #       image {
    #         cloudinary
    #         title
    #         alt
    #       }
    #       link {
    #         type
    #         id
    #       }
    #       lang
    #       prio
    #     }
    #   }
    # }
  }
`